import _ from "lodash";

export function slugify(text: string) {
  return text
    .replace(/^\/+/, "")
    .replace(/[^a-z0-9]+/gi, "-")
    .replace(/(^-+)|(-+$)/g, "")
    .toLowerCase();
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
}

export function removeHtmlTags(text: string): string {
  return text.replace(/<\/?(p|div|br)>/gi, "\n").replace(/<.*?>/g, "");
}

export function formatNumber(value: number): string {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  if (numericValue % 1 !== 0) {
    return numericValue.toLocaleString(undefined, { minimumFractionDigits: 2 });
  }
  return numericValue.toLocaleString();
}

export function getGreeting() {
  const date = new Date();
  const hour = date.getHours();

  let greeting;

  if (hour >= 5 && hour < 12) {
    greeting = "Good morning ";
  } else if (hour >= 12 && hour < 18) {
    greeting = "Good afternoon ";
  } else {
    greeting = "Good evening ";
  }

  return greeting;
}

export function equalPathnames(path1: string, path2: string) {
  const cleanPath = (path: string) => path.split("?")[0].replace(/\/$/, "");
  return cleanPath(path1) === cleanPath(path2);
}

export function stringOrNull(
  value: FormDataEntryValue | null | undefined | string
) {
  if (_.isEmpty(value)) return null;
  return value as string;
}
